import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitSessionComponent } from './init-session.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [InitSessionComponent]
})
export class InitSessionModule { }
