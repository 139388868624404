import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParentSelfSamplingInstructionComponent } from './parent-self-sampling-instruction.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [ParentSelfSamplingInstructionComponent],
  exports: [ParentSelfSamplingInstructionComponent]
})
export class ParentSelfSamplingInstructionModule { }
