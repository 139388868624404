import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsOfUseComponent } from './terms-of-use.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ManagedTermsOfUseComponent } from './managed-terms-of-use/managed-terms-of-use.component';


@NgModule({
  declarations: [
    TermsOfUseComponent,
    ManagedTermsOfUseComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    TermsOfUseComponent,
    ManagedTermsOfUseComponent
  ]
})
export class TermsOfUseModule { }
