import { DOCUMENT } from "@angular/common";
import { Component, Inject, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, NavigationStart } from "@angular/router";
import { select } from "@ngrx/store";
import * as _ from "lodash";
import { AppSelector } from "./@store/selectors/app.selectors";
import { StsSelectors } from "./@store/selectors/sts.selectors";
import { MonitoringService } from "./services/apm.service";
import { filter, take, tap, withLatestFrom, map, mergeAll } from "rxjs/operators";
import { combineLatest, fromEvent, Observable } from "rxjs";
import { claimValues, EBodyClass, EPageTitle, GTM_PLACEHOLDER_VALUE } from "./appsettings";
import { AuthService } from "./services/auth/auth.service";
import { getRedirectUrl, isPrivateRoute, trimUrl } from "./utils/common.utils";
import { LoggerService } from "./services/logger.service";
import { EStorageKeys } from "./models/storage.model";
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import OpenReplay from '@openreplay/tracker';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { AppCommonServices } from "./app-common.services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MonitoringService],
})
export class AppComponent {
  private prevurl: string;
  private store = this.commonService.store;
  private config = this.commonService.config;
  private router = this.commonService.router;
  private transloco = this.commonService.transloco;

  tracker: OpenReplay;

  isLangLoading$: Observable<any>;
  imgs = new Array();
  forceMFA$ = this.store.pipe(select(StsSelectors.showRequireMFAView));
  useNewNav$: Observable<boolean> = this.store.pipe(
    select(StsSelectors.useNewNav)
  );


  constructor(
    @Inject(DOCUMENT) private document,
    r: Renderer2,
    private commonService: AppCommonServices,
    private title: Title,
    private auth: AuthService,
    private logr: LoggerService,
    private idle: Idle,
    private keepalive: Keepalive,
    private gtmService: GoogleTagManagerService,
  ) {
    this.setPageClass(r);
    this.setTitle(r);
    this._fetchFreshToken();

    // enable idleTimer in EE and DK
    this.config.envConfig$.pipe(
      filter(v=>!!v), take(1),
      withLatestFrom(
        this.store.pipe(select(StsSelectors.isDKInstance)),
        this.store.pipe(select(StsSelectors.isEstoniaInstance)),
        this.store.pipe(select(StsSelectors.useNewUI)),
      ),
      tap(([config, isDK, isEE, isNewUI]) => {
        if (isDK || isEE) {
          const idleTimeTemp = config.eeIdleTimeOutInSeconds || 900;
          const idleTime = Number(idleTimeTemp);

          this.logr.log({ idleTime });
          this._enableIdleTimer(idleTime);
        }

        if (isNewUI) { this.subscribeToLangChanges(); }
        this.setCountries();
      })).subscribe();

    this.config.envConfig$
      .pipe(filter((v) => !!v && v.EnableGoogleTag.toLowerCase() == claimValues.TRUE), take(1))
      .subscribe((v) => {
        this.router.events.forEach((item) => {
          if (item instanceof NavigationEnd && this.gtmService.googleTagManagerId != GTM_PLACEHOLDER_VALUE) {
            const gtmTag = {
              event: "page",
              pageName: item.url,
            };

            this.gtmService.pushTag(gtmTag);
          }
        });
      });
  }

  private setCountries() {
    const changes$ = this.transloco.langChanges$;
    const authUrl: string = this.config._envConfig.BaseAuthUrl;
    // if language changes reload country dropdowns with correct active-language http header
    changes$.pipe(tap(async locale => await this.config.getCountries(authUrl).toPromise())).subscribe();
  }

  signout() {
    this.auth.startSignoutMainWindow();
  }

  ngOnInit() {
    this.pload(
      "assets/images/spinner.gif",
      "assets/images/activation-link-used.png",
      "assets/images/success-icon.png"
    );

    // enable OpenReplay
    this.store
      .pipe(select(StsSelectors.getUserUserId))
      .pipe(
        withLatestFrom(
          this.config.envConfig$,
          this.store.pipe(select(StsSelectors.getEnableOpenReplay)),
          this.store.pipe(select(StsSelectors.getAppEnvironment))
        ),
        tap(([userId, config, openReplayEnabled, env]) => {
          this.logr.log({ config, openReplayEnabled, userId, env });
          if (userId) {
            const { OpenReplayProjectKey, OpenReplayIngestPoint } = config;
            this.logr.log({ OpenReplayProjectKey, OpenReplayIngestPoint });
            if (openReplayEnabled) {
              this.tracker = new OpenReplay({
                projectKey: OpenReplayProjectKey,
                ingestPoint: OpenReplayIngestPoint,
              });

              this.tracker.start({
                userID: userId,
                metadata: { environment: env },
              });
            } else {
              if (this.tracker?.isActive) {
                this.tracker.stop();
              }
            }
          }
        })
      )
      .subscribe();

      setTimeout(() => {
        this.isLangLoading$ = this.store.pipe(select(AppSelector.isLanguageLoading));
      }, 0);

  }

  pload(...args: any[]): void {
    for (let i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
    }
  }

  setTitle(r: Renderer2) {
    combineLatest([
      this.store.pipe(select(StsSelectors.isDKInstance)),
      this.store.pipe(select(StsSelectors.isEstoniaInstance)),
      this.store.pipe(select(StsSelectors.isAppWhiteLabeled)),
    ])
      .pipe(
        take(1),
        tap(([isDKTenant, isEETenant, isWhiteLabeled]) => {
          let title = EPageTitle.DEFAULT;
          if (isWhiteLabeled) {
            if (isDKTenant) {
              r.addClass(document.body, EBodyClass.COVID_DK);
              title = EPageTitle.COVID_DK;
            } else if (isEETenant) {
              title = EPageTitle.ESTONIA;
            }
          } else {
            r.removeClass(document.body, EBodyClass.COVID_DK);
          }

          this.title.setTitle(title);
        })
      )
      .subscribe();
  }

  setPageClass(r: Renderer2) {
    this.router.events
      .pipe(
        withLatestFrom(this.store.select(StsSelectors.getWhiteLabelName)),
        tap(([event, whiteLabelName]) => {
          // set class based on tenant name
          if (whiteLabelName) {
            r.addClass(document.body, `tenant-${_.kebabCase(whiteLabelName)}`);
          }

          // set body class based on route path
          if (event instanceof NavigationStart) {
            if (this.prevurl) {
              r.removeClass(document.body, this.prevurl);
            }
            let currentUrlSlug = _.kebabCase(event.url.slice(1) || "");
            if (currentUrlSlug) {
              r.addClass(document.body, currentUrlSlug);
            }
            this.prevurl = currentUrlSlug;
          }
        })
      )
      .subscribe();
  }

  private _fetchFreshToken() {
    const referrer = trimUrl(window.document?.referrer);
    const origin = window.location?.host;

    this.logr.log({ from: referrer, to: origin });
    if (referrer != origin) {
      const redirect = getRedirectUrl();
      const routes = this.router.config;
      const privateRoute = isPrivateRoute(redirect, routes);
      this.logr.log(
        "Check if redirectURL is private route before calling startSigninMainWindow():",
        { privateRoute, redirect }
      );

      if (privateRoute) {
        document.querySelector("body").classList.add("signin-redirecting");
        this.auth.signinRedirectTrigger$.next(Date.now());
        if (redirect) {
          localStorage.setItem(EStorageKeys.REDIRECT_URL, redirect);
        }
      }
    }
  }

  private _enableIdleTimer(idleTimerInSeconds: number) {
    this.idle.setIdle(30); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(idleTimerInSeconds); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setAutoResume(AutoResume.idle);

    this.idle.onTimeout.subscribe(() => {
      this.logr.log({ onTimeout: true });
      this.auth.isLoggedInObs().pipe(
        take(1), tap(isLoggedIn => {
          this.logr.log({ isLoggedIn });
          if (isLoggedIn) {
            this.auth.startSignoutMainWindow();
          }
        })
      ).subscribe();
    });
    this.idle.onIdleStart.subscribe(() => this.logr.log("You've gone idle!"));
    this.idle.onTimeoutWarning.subscribe((countdown) =>
      this.logr.log("You will time out in " + countdown + " seconds!")
    );

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => this.logr.log("User is idle!"));

    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
  }

  cookieBotDetailToggled = false;
  cookieBotFrame: HTMLIFrameElement;

  private createIframes(locale:string) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `/assets/iframe/cookiebot-${locale}.html`);
    iframe.setAttribute('data-consent', 'marketing');
    iframe.setAttribute('data-locale', locale)
    return iframe;
  }

  private subscribeToLangChanges() {
    const changes$ = this.transloco.langChanges$;
    const isCookieBotEnabled = (this.config._envConfig.CookieBotEnabled || '').toLowerCase() === 'true';
    if (!isCookieBotEnabled) return;
    changes$.pipe(
      // test if consent has already been given then no need to show again.
      filter(() => !document.cookie.includes('CookieConsent')),
    ).subscribe(locale => {

      if (this.cookieBotFrame?.getAttribute('data-locale') == locale) {
        return false;
      }

      if (!document.querySelector('.cookiebot-iframes')) {
        // creates the iframe group for each supported locale
        const iFrameContainer:HTMLDivElement = this.document.createElement('div');
        iFrameContainer.classList.add('cookiebot-iframes');
        const availableLocales = this.transloco.getAvailableLangs() as string[];
        availableLocales.forEach((locale:string) => iFrameContainer.appendChild(this.createIframes(locale)));
        this.document.querySelector('body').appendChild(iFrameContainer);
      }
      // hide all visible iframes
      document.querySelectorAll('.cookiebot-iframes iframe').forEach((el:Element) => el.classList.contains('visible') && el.classList.remove('visible'));

      this.cookieBotFrame = document.querySelector(`.cookiebot-iframes [data-locale='${locale}']`);

      if (this.cookieBotFrame) {
        this.cookieBotFrame.contentWindow.location.reload();
        const expandElementSelector = '#CybotCookiebotDialogBodyEdgeMoreDetailsLink, #CybotCookiebotDialogBodyLevelButtonCustomize';
        // waits for iframe to be ready, then waits for the document object to load, then wait for cookiebot to be displayed
        const onIframeReady = fromEvent(this.cookieBotFrame, 'load').pipe(
          map(() => fromEvent(this.cookieBotFrame.contentWindow, 'load').pipe(
            map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnDialogDisplay'))
          ))
        );
        const onMoreDetails = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow.document.querySelectorAll(expandElementSelector), 'click')));
        const onAcceptClick = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnAccept')));
        const onDeclineClick = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnDecline')));
        // hide the active iframe once the user accepts.
        onAcceptClick.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.classList.remove('visible') });
        // hide the active iframe once the user declines.
        onDeclineClick.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.classList.remove('visible') });
        // expand theh container height when more info is toggled
        onMoreDetails.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.style.height = '70vh'; this.cookieBotDetailToggled = true });
        //show only relevant cookiebot popup
        onIframeReady.pipe(mergeAll()).subscribe(() => {
          if (this.cookieBotDetailToggled)  this.cookieBotFrame.contentWindow.document.getElementById('CybotCookiebotDialogBodyEdgeMoreDetailsLink').click();
          this.cookieBotFrame.classList.add('visible');
        });
      }
    })
  }
}
