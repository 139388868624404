import { Routes } from "@angular/router";
import { LocalStorageKeys } from "../models/storage.model";
import { AuthGuard } from "../services/authGuard/auth.guard";
import { formatLocale } from "../plasma-ui-common/utils/format-locale";
import * as libphonenumber from "google-libphonenumber";


/**
 * Get the "langCode" value from URL query string
 * @returns String. Returns "langCode" value from query string. If not exists, it will return null.
 */
export const getLangCodeFromQueryString = (): string => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let locale: string;
  urlSearchParams.forEach((v, k) => {
    if (k.toLowerCase() == "langcode") {
      locale = v;
    }
  });
  return locale ?? null;
};

/**
 * Get the saved language code from session staorage.
 * @returns String. Returns the saved language code in the session storage.
 */
export const getLangCodeFromLocalStorage = (): string => {
  return formatLocale(sessionStorage.getItem(LocalStorageKeys.language));
};

/**
 * Reload the current page
 */
export const pageReload = (): void => {
  location.reload()
};

export const showCookieBot = (
  value: boolean,
  delayInMilliseconds: number = 1000
): void => {
  setTimeout(() => {
    const element = document.getElementById("CybotCookiebotDialog");
    if (!!element) {
      if (value) {
        element.classList.add("show");
      } else {
        element.classList.remove("show");
      }
    }
  }, delayInMilliseconds);
};

/**
 * Check if a variable is string or not
 * @param data
 * @returns Boolean.
 */
export const isString = (data: string) => {
  return typeof data === "string";
};

/**
 * Returns the host from a url
 * @param url
 * @returns
 */
export const trimUrl = (url: string = ""): string => {
  const link = document.createElement("a");
  link.href = url;
  return link.host;
};

/**
 * @returns String. returns the redirect url
 */
export const getRedirectUrl = (): string => {
  return location.pathname + location.search;
};

/**
 * Boolean. Returns "true" if path is a private route, otherwise, returns "false"
 * @param path String. The URL that needs to be checked.
 * @param routes Array. Routes from Router.config provided by Angular
 * @returns Boolean
 */
export const isPrivateRoute = (path: string, routes: Routes): boolean => {

  // get the first path from the redirect url
  const link = document.createElement("a");
  link.href = path;
  const tempPath = link.pathname?.charAt(0) === "/" ? link.pathname.substring(1) : link.pathname;
  const firstPath: string = tempPath?.split("/")?.filter((link) => link)[0] ?? undefined;

  // if URL has no specific firstPath, consider the URL as private route
  if (firstPath === undefined) {
    return true;
  }

  // check if firstPath is private or public based from Router.config settings
  const route = routes.find(
    (route) => route.path?.toLowerCase() == firstPath?.toLowerCase()
  );
  const canActivates: any[] = route?.canActivate;
  return canActivates?.some(
    (canActivate: any) =>
      canActivate?.toString() == AuthGuard.toString()
  )
};

export const getPhone = (phone: string) => {
  try {
    // https://github.com/ruimarinho/google-libphonenumber
    const PNF = libphonenumber.PhoneNumberFormat;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    if (phone && phoneUtil.isValidNumber(phoneUtil.parse(`+${phone}`))) {
      const number = phoneUtil.parseAndKeepRawInput(`+${phone}`, "");
      return {
        countryCode: phoneUtil.getRegionCodeForNumber(number),
        dialCode: `+${number.getCountryCode()}`,
        e164Number: phoneUtil.format(number, PNF.E164),
        internationalNumber: phoneUtil.format(number, PNF.INTERNATIONAL),
        nationalNumber: phoneUtil.format(number, PNF.NATIONAL),
        number: String(number.getNationalNumber()),
      };
    } else {
      console.warn("INVALID PHONE NUMBER -----> "+ phone);

      // special override for Estonia to be able to accept sample numbers in https://github.com/SK-EID/MID/wiki/Test-number-for-automated-testing-in-DEMO
      if (`${phone}`.replace('+','').slice(0,3) == '372') {
        return { number: `${phone}`.slice(3), countryCode: 'EE' };
      }

      return {
        number: phone,
        invalid: true
      };
    }
  } catch (error) {
    console.warn( "Error parsing phone. returning invalid raw number.", error );
    return { number: phone };
  }
}
