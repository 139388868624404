import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-renewtoken',
  templateUrl: './renewtoken.component.html',
  styleUrls: ['./renewtoken.component.scss']
})
export class RenewtokenComponent implements OnInit {

  constructor(private config: AuthService) {
  }

  ngOnInit() {
    this.config._manager.signinSilentCallback().catch((e) => {
      console.log("Test Error");
      console.error("silent-renew error:", e);
    });
  }

}
