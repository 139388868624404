import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/@store/reducers';
import * as RegisterProfileReducer from 'src/app/@store/reducers/register-profile.reducer'
import { claimTypes, userTypes } from 'src/app/appsettings';

const State = (state: AppState) => state[RegisterProfileReducer.featureKey]

export const registerProfile = createSelector(State, s => s.registerProfile);
export const registerProfileLoading = createSelector(State, s => s.registerProfileLoading);
export const registerProfileError = createSelector(State, s => s.registerProfileLoadError);
export const registerProfileErrorObject = createSelector(State, s => s.registerProfileLoadErrorObject);
export const registerProfileSubmitted = createSelector(State, s => s.registerProfileSubmitted);
export const submitRegisterProfileLoading = createSelector(State, s => s.submitRegisterProfileLoading);
export const submitRegisterProfileError = createSelector(State, s => s.submitRegisterProfileError);
export const registerSynlabAccessUser = createSelector(State, s => {
  return s.registerProfile?.some(c => c.claimType == claimTypes.USER_TYPE && c.claimValue == userTypes.SYNLAB_ACCESS_USER)
})

export const registerProfileLinkInvalid = createSelector(registerProfileErrorObject, s => s?.status === 404 || s?.status === 400);
export const registerProfileLinkUsed = createSelector(registerProfileErrorObject, s => s?.status === 422);
export const registerSubmittedSuccessfully = createSelector(
  registerProfileSubmitted,
  submitRegisterProfileLoading,
  submitRegisterProfileError,
  (submitted, submitting, error) => submitted && !submitting && !error
)
