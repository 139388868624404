import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MedioService } from "./medio.service";
import { take } from "rxjs/operators";
import { SpinnerModule } from "src/app/plasma-ui-common/synlab-access-ui-components/spinner/spinner.component";
import { EStorageKeys, LocalStorageKeys } from "src/app/models/storage.model";

@Component({
  selector: "app-medio-redirect",
  standalone: true,
  imports: [
    CommonModule,
    SpinnerModule
  ],
  templateUrl: "./medio-redirect.component.html",
  styleUrls: ["./medio-redirect.component.scss"],
})
export class MedioRedirectComponent implements OnInit {
  constructor(
    private readonly medio: MedioService
  ) {}

  ngOnInit(): void {
    this.medio.redirect();
  }
}
