import { Injectable } from '@angular/core';
import { SessionTransfer, SessionTransferDto, SessionTransferError, SessionTransferErrorCode as ErrCode } from 'src/app/models/sessionTransfer.model';

import * as aesjs from "aes-js";
import * as base64 from "byte-base64";
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthSessionTransferService {

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  getSessionTransfer$(args: { hashKey: string; sessionId: string; }) {
    return this.http
      .get<SessionTransferDto>(`${this.config.baseAuthUrl}/session-transfer-store/${args.sessionId}`)
      .pipe(
        map((resp) => this.dtoToModel(resp, args.hashKey))
      );
  }
  
  dtoToModel(dto: SessionTransferDto, hashKey: string) {
    // Convert dto.value to bytes

    console.log(dto);
    console.log(hashKey);


    let valueBytes: Uint8Array;

    try {
      valueBytes = base64.base64ToBytes(dto.value);
    } catch (err) {
      console.error("Failed to convert base64 dto.value to bytes", err);
      throw new SessionTransferError(ErrCode.DtoValueToBytesFailed);
    }

    if (valueBytes.length <= 16) {
      console.error("dto.value must contain at least 17 bytes of data");
      throw new SessionTransferError(ErrCode.InvalidValueBytes);
    }

    // Split dto.value into iv and decrypted data (iv is 16 bytes long and placed first)

    const ivBytes = valueBytes.subarray(0, 16);
    const encryptedBytes = valueBytes.subarray(16, valueBytes.length);

    let hashKeyBytes: Uint8Array;

    try {
      hashKeyBytes = base64.base64ToBytes(hashKey);
    } catch (err) {
      console.error("Failed to convert base64 hashKey to bytes", err);
    }

    // Create a decryptor with the hashKey and iv to decrypt the data

    let aesCbcDecryptor: aesjs.ModeOfOperation.ModeOfOperationCBC;

    try {
      aesCbcDecryptor = new aesjs.ModeOfOperation.cbc(hashKeyBytes, ivBytes);
    } catch (err) {
      console.error("Failed to create decryptor", err);
      throw new SessionTransferError(ErrCode.CreateDecryptorFailed);
    }

    // Decrypt data

    let decryptedBytesWithPadding: Uint8Array;

    try {
      decryptedBytesWithPadding = aesCbcDecryptor.decrypt(encryptedBytes);
    } catch (err) {
      console.error("Failed to decrypt bytes", err);
      throw new SessionTransferError(ErrCode.DecryptionFailed);
    }

    // Remove padding
    // Some padding is probably added by the encryptor and is 0f in hex, aka 15.
    // Other padding (3) are from base64 '=' padding
    // Other padding are (1) causing a weird utf8 symbol

    let decryptedBytes: Uint8Array;

    try {
      decryptedBytes = this.removePadding(this.removePadding(this.removePadding(decryptedBytesWithPadding, 15), 3), 1);
    } catch (err) {
      console.error("Failed to remove padding from encrypted bytes", err);
      throw new SessionTransferError(ErrCode.RemovePaddingFailed);
    }

    // Interpret the decrypted words/bytes as Utf8
    let json: string;
    try {
      json = new TextDecoder().decode(decryptedBytes);

      if (!json) {
        throw new Error("Decoded json string was falsy");
      }
    } catch (err) {
      console.error("Failed to decode UTF8 bytes", err);
      throw new SessionTransferError(ErrCode.UTF8DecodeFailed);
    }

    try {
      json = this.trimJson(json);
    } catch (err) {
      console.error("Failed to trim json", err);
      throw new SessionTransferError(ErrCode.TrimJsonFailed);
    }

    let obj: SessionTransfer;
    try {
      obj = JSON.parse(json);

      if (!obj) {
        throw new Error("obj was undefined");
      }
    } catch (err) {
      console.error("Failed to parse json string to object", err);
      throw new SessionTransferError(ErrCode.JsonParseFailed);
    }

    try {
      if (!obj.accessToken) {
        throw new Error("accessToken was missing on obj");
      }

      if (!obj.idToken) {
        throw new Error("idToken was missing on obj");
      }
    } catch (err) {
      console.error("Parsed object was invalid", err);
      throw new SessionTransferError(ErrCode.ParsedObjectInvalid);
    }

    return obj;
  }

  private removePadding(array: Uint8Array, paddingValue: number) {
    const reversed = array.reverse();
    const firstNonPaddingEntry = reversed.find((val) => val !== paddingValue);
    const nonPaddingIndex = firstNonPaddingEntry ? reversed.indexOf(firstNonPaddingEntry) : undefined;
    return reversed.subarray(nonPaddingIndex, reversed.length).reverse();
  }

  // Tries to find a json object in the string to isolate it and remove junk that might be present before and after
  private trimJson(str: string) {
    if (!str) {
      throw new Error("Could not trim empty json string");
    }

    let firstIndex: number | undefined;

    for (let i = 0; i < str.length; i++) {
      if (str[i] === "{") {
        firstIndex = i;
        break;
      }
    }

    if (firstIndex === undefined) {
      throw new Error("Could not find '{' in json");
    }

    let lastIndex: number | undefined;

    for (let i = 0; i < str.length; i++) {
      const index = str.length - i - 1;
      if (str[index] === "}") {
        lastIndex = index;
        break;
      }
    }

    if (lastIndex === undefined) {
      throw new Error("Could not find '}' in json");
    }

    return str.substring(firstIndex, lastIndex + 1);
  }
}
