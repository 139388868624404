
<div class="background">
  <img src="/assets/dxp/img/background.png" alt="" srcset="">
</div>

<div class="wrapper" *transloco="let t; read: 'activateAccount'">
  <mat-spinner *ngIf="loading$ | async" diameter="70"></mat-spinner>
  <div *ngIf="success$ | async as success; else notSuccessful" class="status status-success">
    <ng-container *ngIf="!(loading$ | async)">
      <h1>{{ t('successH1') }}</h1>
      <p>{{ t('successParagraph') }}</p>
      <a role="button" class="button" href="javascript:void(0);" (click)="redirectToLogin()">{{ t('loginButton') }}</a>
    </ng-container>
  </div>

  <ng-template #notSuccessful>
    <div *ngIf="!(loading$ | async) && !isActiveUser && !isLinkExpired && !isDuplicateError" class="status status-failure">
      <p>{{ t('errorH1') }}</p>
      <h1>{{ (error$ | async) || t('errorParagraph')}}</h1>
    </div>
    <div *ngIf="!(loading$ | async) && isLinkExpired" class="status status-failure">
      <h1>{{t('shfyErrorH1')}}</h1>
      <p>{{t('shfyErrorParagraph')}}</p>
      <a role="button" class="button" href="javascript:void(0);" (click)="redirectToLogin()">{{t('shfyLoginButton')}}</a>
    </div>
    <div *ngIf="!(loading$ | async) && isActiveUser" class="status status-failure">
      <h1>{{t('shfyActiveUserErrorH1')}}</h1>
      <p>{{t('shfyActiveUserErrorParagraph')}}</p>
    </div>
    <div *ngIf="!(loading$ | async) && isDuplicateError" class="status status-failure">
      <h1>{{t('shfyDuplicateFiscalCodeErrorH1')}}</h1>
      <p [innerHTML]="(error$ | async) || t('errorParagraph')"></p>
    </div>
  </ng-template>

</div>

