/**text for dk delete section*/
export const dk_text = {
  'delete': {
    'deleteMyUserAccount': 'Delete my user account',
    'deleteUserAccount': 'Delete User Account',
    'instruction1': 'You are about to start the process of deleting your user account.',
    'instruction2': 'If you still want to delete your user account, click \"Delete My User Account\".',
    'confirmInstruction1': 'This action cannot be undone.',
    'confirmInstruction2': 'Click the button below to delete your user account',
    "confirmDeleteSuccessMessage": "You will be redirected to the login screen after closing this dialog box",
  },
  'sendForgotPassword': {
    'heading': 'Enter the E-Mail address associated with your Covidresults.dk account.',
  }
};
