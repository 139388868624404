import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralTermsComponent } from './general-terms.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';



@NgModule({
  declarations: [
    GeneralTermsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    GeneralTermsComponent
  ]
})
export class GeneralTermsModule { }
