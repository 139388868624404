import { createAction, props } from "@ngrx/store";

const setVisibility = createAction(
  "[Header] Set Visibility",
  props<{ visible: boolean}>()
)

export const HeaderActions = {
  setVisibility
}
