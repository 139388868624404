import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { ErrorCode } from 'src/app/appsettings';
import { dk_text } from 'src/app/models/dk_text';
import { emailPattern } from 'src/app/modules/shared/regex-pattern';
import { ApiService } from 'src/app/services/api/api.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-send-forgot-password',
  templateUrl: './send-forgot-password.component.html',
  styleUrls: ['./send-forgot-password.component.scss']
})
export class SendForgotPasswordComponent implements OnInit, OnDestroy {
  dk_text = dk_text.sendForgotPassword;
  isDKInstance$: Observable<boolean> = this.store.select(
    StsSelectors.isDKInstance
  );
  public isUkShfyInstance$: Observable<boolean> = this.store.select(StsSelectors.isUkShfyInstance);
  readonly endpoint = '/account/sendforgotpassword';
  readonly emailPattern = new RegExp(emailPattern);
  returnUrl = '';
  form: UntypedFormGroup;
  sub: Subscription;
  message = '';
  loading = false;
  submitted = false;

  isUserDeactivated: boolean = false;
  deactivatedMessage: string = null;

  constructor(
    private api: ApiService,
    private activeRoute:ActivatedRoute,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private config: ConfigService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
    });
    this.returnUrl = btoa(encodeURIComponent(this.activeRoute.snapshot.queryParams.returnUrl));
  }

  get email() {
    return this.form.get('email');
  }

  ngOnInit() {
    this.sub = this.form.get('email').valueChanges.subscribe(n => this.submitted = false)
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSubmit() {
    this.isUserDeactivated = null;
    this.message = null;
    this.loading = true;

    const email = encodeURIComponent(this.email.value);
    this.api.post(`${this.endpoint}?returnUrl=${encodeURIComponent(this.returnUrl)}&email=${email}`).subscribe(
      () => {
        this.message = `sendSuccess`;
        this.loading = false;
        this.submitted = true;
      },
      e => {
        console.error(e);
        this.loading = false;
        this.submitted = true;

        const { error } = e;
        if (error) {
          const { errorCode, errorMessage } = error;
          this.isUserDeactivated = errorCode == ErrorCode.USER_DEACTIVATED;
          this.deactivatedMessage = errorMessage;
        }
      }
    )
  }

  onCancel() {
    window.location.href = `${this.config.baseAuthUrl}/Account/Login${window.location.search}`;
  }

  onClear(controlName:string) {
    this.form.get(controlName).reset();
  }

}
