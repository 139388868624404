import { claimValues } from "src/app/appsettings";

export const firstnameClaimType = "given_name",
  lastnameClaimType = "family_name",
  activeClaimType = "active",
  userIdClaimType = "userId",
  userTypeClaimType = "user_type",
  linkedAccountsClaimType = "linked_accounts",
  localeClaimType = "locale",
  familyNameClaimType = "family_name",
  givenNameClaimType = "given_name",
  usernameClaimType = "username",
  countryResidenceClaimType = "country_residence",
  addressClaimType = "address",
  cityClaimType = "city",
  zipCodeClaimType = "zipcode",
  poolNameClaimType = "given_name",
  holderNameClaimType = "family_name",
  synlabIdClaimType = "synlab_id",
  petCategoryClaimType = "pet_category",
  petCategoryNameClaimType = "pet_category_name",
  wardVerifiedClaimType = "ward_verified";

/** Account model */
export interface Account extends Array<Claim> {}

/** Claims model */
export interface Claim {
  claimType: string;
  claimValue: string;
}
/** Converts `object` to list of Claims (`Claim[]`). Assumes object is Record<string,string>*/
export function objectToClaims<T extends Record<string, string>>(obj: T): Claim[] {
  return Object.keys(obj).map(key => ({
    claimType: key,
    claimValue: ['number', 'boolean'].some(t => typeof obj[key] === t) ? obj[key].toString() : obj[key]
  }))
}

/** Get a claim value with  claimType provided
 * @param claimType `claimType` value to find in `claims`
 * @param claims list of claims
 */
export function getClaimValueWithType(
  claimType: string,
  claims: Claim[]
): string {
  if (claimType && Array.isArray(claims)) {
    const claim = claims.find((c) => c.claimType === claimType);
    if (claim) {
      return claim.claimValue;
    }
  }
  return undefined;
}
/** find claim with exact match of type and value.
 * can be used for multiple types
 */
export function hasClaimWithTypeAndValue(
  claimType: string,
  claimValue: string,
  claim: Claim[]
): boolean {
  return claim?.some(c => c.claimType === claimType && c.claimValue === claimValue);
}
/** function to check if claim value of claim type is true */
export const claimIsTrue = (claimType: string, claims: Claim[]): boolean =>
  CheckIfTrueString(claims.find(c => c.claimType === claimType)?.claimValue);

/**
 * Update claims with new values that has been updated.
 * add nonexisting claims at the end of the list.
 * @param oldClaims old claims to be updated
 * @param newClaims new claims with updates
 */
export function updateClaims(oldClaims: Claim[], newClaims: Claim[]): Claim[] {
  let resultingClaims = oldClaims.slice();
  const indexlist = resultingClaims.map((x) => x.claimType);
  newClaims.forEach((x) => {
    let i = indexlist.indexOf(x.claimType);
    if (i > -1) {
      resultingClaims[i] = x;
    } else {
      resultingClaims.push(x);
    }
  });
  return resultingClaims;
}
export function removeNullClaims(claims: Account): Account {
  return claims?.filter(c => c.claimValue !== null && c.claimValue !== undefined)
}

export function removeClaimsForPtEe(claims: Account): Account {
  return claims?.filter(c => c.claimType !== 'modified_by_name')
}

/** Checks if object is actually a `Claim`
 * @param obj object to check
 */
export function isClaim(obj: any): obj is Claim {
  return obj && obj.claimType && typeof obj.claimType === "string";
}

/**
 * get whole name from claims
 * @param claims list of claims
 */
export function getNameFromClaims(claims: Account) {
  const fname = (
      getClaimValueWithType(firstnameClaimType, claims) || ""
    ).trim(),
    lname = (getClaimValueWithType(lastnameClaimType, claims) || "").trim();
  return `${fname ? fname + " " : ""}${lname ? lname : ""}`;
}

export function CheckIfTrueString(str: string) {
  return typeof str === "string" && str.trim().toLowerCase() === claimValues.TRUE;
}

export function getPetName(claims: Account) {
  const name = (getClaimValueWithType(firstnameClaimType, claims) || "").trim();
  return `${name ? name : ""}`;
}

export function getPetCategory(claims: Account) {
  return getClaimValueWithType(petCategoryClaimType, claims) || "other";
}

export function getPetCategoryName(claims: Account) {
  let petCategoryName = "";
  const category = getPetCategory(claims);

  if (category == "other") {
    petCategoryName = (
      getClaimValueWithType(petCategoryNameClaimType, claims) || ""
    ).trim();
  }
  return petCategoryName ? petCategoryName : category;
}

export function getWardVerifiedClaimValue(claims: Account) {
  let verified = (
    getClaimValueWithType(wardVerifiedClaimType, claims) || ""
  ).trim();

  return verified.toLowerCase() == claimValues.TRUE;
}
