import { Injectable } from "@angular/core";
import { from, of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom, delay} from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { STSActions } from '../actions/sts.actions';
import { CountryService } from "src/app/services/country/country.service";
import { select, Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { ApiService } from "src/app/services/api/api.service";
import { claimValues } from "src/app/appsettings";

@Injectable()
export class StsEffects {

  constructor(
    private actions$: Actions,
    private countryService: CountryService,
    private store: Store<AppState>,
    private api: ApiService,
  ) { }

  loadCountry$ = createEffect(() => this.actions$.pipe(
    ofType(STSActions.loadCountry),
    switchMap(({code}) => from(this.countryService.getByCode(code)).pipe(
      map(country => STSActions.loadCountrySuccess({ payload: country })),
      catchError(async () => STSActions.loadCountryFailed())
    ))
  ));

  fetchGeneplanetHasOrders = createEffect(() =>
      this.actions$.pipe(
        ofType(STSActions.fetchHasGeneplanetOrders),
        delay(3000),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getSynlabId)),
          this.store.pipe(select(StsSelectors.isSynlabAccessUser)),
          this.store.pipe(select(StsSelectors.getAccountsBaseUrl)),
        ),
        switchMap(([action, synlabId, isSAuser, baseUrl]) => {
          if (synlabId && isSAuser) {
            return this.api.getHttp(`${baseUrl}/api/results/ee-has-gp-result/${synlabId}`).pipe(
              map(response => `${response}`.toLowerCase() == claimValues.TRUE),
              catchError(() => of(false))
            )
          }
          return of(null)
        }),
        map(result => STSActions.setHasGeneplanetOrders({ hasOrders: result}))
      ),
  )
}
