<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet">

<div class="container">
    <div class="row navbar-brand">
        <div class="col-12">
            <img src="assets/img/synlab-logo.svg" alt="Synlab's logo" class="pl-logo-1 mt-4 mb-3"/>
        </div>
    </div>
</div>
<div class="container body text-synlab mt-3 mb-5" >
    <div class="row">
        <div class="col-12">
            <h2>COVID-19</h2>
            <h3>Anleitung für den Lolli-PCR-Pool zu Hause</h3>
        </div>
        <div class="col-12">
          <p>
            Um die Pandemie im Segment Schulen und Kindergärten besser
            unter Kontrolle zu bringen, wird Ihrem Kind die Testung
            auf eine Infektion mit SARS-CoV-2 empfohlen
          </p>
          <p>
            In der Schule haben die Kinder bereits eine erste Testung durchgeführt,
            bei der mehrere Proben in einer PCR gemeinsam gemessen wurden („Pool-Probe“).
            Fällt dieser Pool-Test positiv aus, sind Kinder der Klasse mit SARS-CoV-2 infiziert.
            Sollte dies zutreffen, bekommen Sie diesbezüglich weitere Information über Ihre Schule.
            Bitte öffnen Sie erst dann den Druckverschlussbeutel und führen die Probennahme durch.
            Bis dahin bewahren Sie den Beutel bitte bei Raumtemperatur auf.
          </p>
          <p>
            Um die Probennahme für Ihr Kind sicher und angenehm zu gestalten,
            kommt auch bei der Abnahme zu Hause die „LolliMethode“ zum Einsatz.
            Im von der Schule übergebenen Druckverschlussbeutel finden Sie einen
            sterilen Tupfer, den Sie Ihrem Kind geben und an dem, wie bei einem Lutscher/Lolli,
            30 Sekunden gelutscht wird. Im Anschluss verpacken Sie den Tupfer in der mitgelieferten
            Probentüte und bringen diese in die Schule, von wo der Transport ins SYNLAB-Labor erfolgt.
            Dort wird mit einer sehr sensitiven PCR-Methode die Analyse durchgeführt.
          </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
            <h5 class="font-weight-bold">
              Der Druckverschlussbeutel enthält folgende Materialien:
            </h5>
            <ol type="a">
              <li>Probentüte</li>
              <li>Tupfer mit Transportröhrchen</li>
              <li>
                ein von der Schule ausgedruckter QR-Code für die SYNLAB Access-App
              </li>
            </ol>
        </div>
        <div class="col-md-8 col-sm-6 col-xs-12">
            <div class="card ">
                <img src="assets/images/parent-self-sampling-instructions/parent-header-image-1.PNG" class="card-img img-fluid rounded " alt="Self-Sampling instruction"/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 border-bottom border-primary">
            <h2>Vorbereitung</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/parent-self-sampling-instructions/step-1.PNG" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 1"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">1</h1>
              </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <p>
            Beschriften Sie den Aufkleber der Probentüte mit
            dem Namen Ihres Kindes.
          </p>
          <p>
            <strong>
              Sollte ein Strichcode beiliegen und noch
              nicht auf dem Transportröhrchen kleben,
              bringen Sie ihn bitte in Längsrichtung an.
              Nicht um das Röhrchen wickeln!
            </strong>
          </p>
          <p>
            Laden Sie die SYNLAB Access-App herunter:
          </p>
          <img src="assets/images/parent-self-sampling-instructions/synlab-access-qr-code.PNG" alt="SYNLAB Access QR code" class="d-flex mx-auto"/>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/parent-self-sampling-instructions/step-2.PNG" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 2"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">2</h1>
              </div>
            </div>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/parent-self-sampling-instructions/step-3.PNG" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 3"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">3</h1>
              </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <p>
              Registrieren Sie sich in der App mit Ihrer E-MailAdresse. Sie erhalten anschließend eine E-Mail
              mit einem Link, den Sie bitte anklicken, um auf
              der Seite Ihre Daten zu vervollständigen und ein
              Passwort zu wählen. Nach Abschluss der Registrierung können Sie sich in der App einloggen.
              In der App klicken Sie auf „Selbst-Testung starten“. Sie werden nun aufgefordert, den durch die
              Schule zur Verfügung gestellten Zugangscode
              einzugeben, der die kostenlose Nachtestung Ihres
              Kindes freischaltet.
            </p>
            <p>
              Nach Eingabe der Daten scannen Sie den Strichcode auf dem Tupfertransportröhrchen.
            </p>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/parent-self-sampling-instructions/step-4.PNG" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 4"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">4</h1>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 border-bottom border-primary">
            <h2>Anleitung für die Probenentnahme</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/self-sampling-instructions/step-5.png" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 5"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">5</h1>
              </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <p>
              Entnehmen Sie den Tupfer im Transportröhrchen
              aus dem Druckverschlussbeutel. Ziehen Sie den
              Tupfer aus dem Transportröhrchen heraus und
              geben Sie ihn Ihrem Kind.
            </p>
            <p>
              Ihr Kind nimmt den Tupfer mit der wattierten Seite
              in den Mund und lutscht 30 Sekunden darauf: Mit
              der Zunge hin- und herbewegen. <strong>Nicht kauen!</strong>
            </p>
            <p>
              Anschließend den Tupfer in das Transportröhrchen stecken und es gut verschließen.
            </p>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/self-sampling-instructions/step-6.png" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 6"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">6</h1>
              </div>
            </div>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/self-sampling-instructions/step-7.png" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 5"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">7</h1>
              </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <p>
              Sie stecken das Transportröhrchen in die Probentüte. Verschließen Sie die Probentüte mit dem Klebeverschluss.<br/>
              Bitte bringen Sie die Probe schnellstmöglich in die
              Schule mit!
            </p>
            <p>
              <strong>
                Informationen zur Rückgabe (insbesondere die Abgabezeiten)
                oder zum weiteren Transport erhalten Sie von Ihrer Schule.
              </strong>
            </p>
            <p>
              <strong>
                Über das Ergebnis der PCR-Analyse werden
                Sie über die SYNLAB Access-App informiert.
              </strong>
            </p>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="card ">
              <img src="assets/images/self-sampling-instructions/step-8.png" class="card-img img-fluid rounded " alt="Self-Sampling instruction number 6"/>
              <div class="card-img-overlay ">
                <h1 class="card-title text-synlab">8</h1>
              </div>
            </div>
        </div>
    </div>
</div>
