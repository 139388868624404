<ng-container *transloco="let t; read: 'delete'">
  <ng-container *ngIf="(!(useNewNav$|async)) && !(isSHFYInstance$ | async)">
    <app-header [showDropdown]="false"></app-header>
  </ng-container>
  <ng-container *ngIf="!(isSHFYInstance$ | async); else shfy">
    <div class="container py-4">
      <div class="row">
        <ng-container [ngSwitch]="isValidDeleteAccountLink$|async">
          <ng-container *ngSwitchCase="true">
            <div class="col-sm-8 m-auto">
              <div class="card py-4 px-4 delete-card">
                <h5 class="text-synlab-primary">{{(isDKInstance$ | async) ? dk_text.deleteUserAccount :t('deleteTitle')}}</h5>
                <p>{{(isDKInstance$ | async) ? dk_text.confirmInstruction2: t('deleteConfirmInstruction1')}}</p>
                <div class="pt-4 btn-sec">
                  <button class="btn btn-primary" (click)="delete()">{{t('confirmButton')}}</button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <ng-container *ngIf="(useNewNav$|async); else oldWarning">
              <div class="syn-container">
                <h2>{{ t('invalidLinkText') }}</h2>
                <button (click)="redirectToContactSynlab()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ 'completeProfile.openUefaLink' | transloco }}
                </button>
              </div>
            </ng-container>
            <ng-template #oldWarning>
              <div class="alert alert-warning mt-5 m-auto" role="alert">
                {{ t('invalidLinkText') }}
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="col-12 d-flex justify-content-center align-items-center pt-5">
              <span>
                <mat-spinner diameter="40" strokeWidth="3"></mat-spinner>
              </span>
            </div>
          </ng-container>
        </ng-container>
        </div>
    </div>
  </ng-container>

  <ng-template #shfy>
    <div class="shfy wrapper">
      <div class="container py-4">
        <div class="row">
          <div *ngIf="(isValidDeleteAccountLink$ | async); else invalidLink" class="col-sm-8 m-auto">
            <div class="card py-4 px-4 delete-card">
              <h5 class="text-synlab-primary delete-account-title">{{(isDKInstance$ | async) ? dk_text.deleteUserAccount :t('deleteTitle')}}</h5>
              <p class="delete-account-message">{{(isDKInstance$ | async) ? dk_text.confirmInstruction2: t('deleteConfirmInstruction1')}}</p>
              <div class="pt-4 btn-sec">
                <button class="delete-btn" (click)="delete()">{{t('confirmButton')}}</button>
              </div>
            </div>
          </div>
          <ng-template #invalidLink>
            <div class="alert alert-warning mt-5 m-auto" role="alert">
              {{ t('invalidLinkText') }}
            </div>
          </ng-template>
          </div>
      </div>
    </div>
  </ng-template>
</ng-container>
