import { createAction, props } from '@ngrx/store';
import { Consents } from 'src/app/pages/index/pages/notification-settings/notification.model';

export const loadProfiles = createAction(
  '[Profile] Load Profiles',
  props<{data: any}>()
);

export const getAuthenticatorUri = createAction(
  '[Profile] Fetched Authenticator Uri Success',
  props<{data: string}>()
);

export const getRecoveryCodes = createAction(
  '[Profile] Fetched Recovery Code Success',
  props<{data: string}>()
);

export const updatePhoneDetails = createAction(
  '[Profile] Fetched Phone Detail Success',
  props<{number: string, country_phone: string}>()
);

export const twoFaEnableSuccess = createAction(
  '[Profile] Two FA Enable Success',
  props<{data: boolean}>()
);

export const updatePhoneTemp = createAction(
  '[Profile] Mobile Number Temp Success',
  props<{number: string, country_phone: string}>()
);

export const setResidence = createAction(
  '[Profile] Set residence country code',
  props<{ countryCode: string }>()
);

export const restoreResidence = createAction(
  '[Profile] Restore residence country code',
  props<{ countryCode: string}>()
);

export const removeStoredResidence = createAction(
  '[Profile] Remove residence country code from storage'
);

export const getProfile = createAction(
  '[Profile] Get Profile',
);

export const getProfileSuccess = createAction(
  '[Profile] Get Profile Success',
  props<{data: any}>()
);

export const getProfileFailed = createAction(
  '[Profile] Get Profile Failed',
  props<{error: any}>()
);

export const getConsent = createAction(
  '[Profile] Get Consent',
  props<{ synlabId? : string, categoryName?: string}>()
);

export const getConsentSuccess = createAction(
  '[Profile] Get Consent Success',
  props<{data: any}>()
);
export const getMarketingConsentsCheckedSuccess = createAction(
  '[Profile] Get Consent Marketing Consent Checked Success',
  props<{value: boolean}>()
);

export const getConsentFailed = createAction(
  '[Profile] Get Consent Failed',
  props<{error: any}>()
);

export const consentOnchanges = createAction(
  '[Profile] Consent Value Changes',
  props<{consents: Consents[]}>()
);

export const clearConsentPayload = createAction(
  '[Profile] Consent Payload clear'
);

export const ProfileActions = {
  getMarketingConsentsCheckedSuccess,
  getProfile,
  getProfileSuccess,
  getProfileFailed,
  loadProfiles,
  getAuthenticatorUri,
  getRecoveryCodes,
  updatePhoneDetails,
  twoFaEnableSuccess,
  updatePhoneTemp,
  setResidence,
  restoreResidence,
  removeStoredResidence,
  getConsent,
  getConsentSuccess,
  getConsentFailed,
  consentOnchanges,
  clearConsentPayload
};
