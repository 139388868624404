<ng-container *transloco="let t; read: 'header'">
  <nav
    aria-label="header-nav"
    class="plasma-header web-header navbar navbar-expand-lg navbar-light bg-light new-header border-bottom box-shadow sticky-top  pl-bg-2 "
    [ngClass]="{'white-labeled-icon': isDKInstance$|async}">
    <button class="navbar-toggler pl-0" type="button" data-toggle="collapse"
      data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="material-icons">menu</span>

    </button>
    <a href="javascript:void(0)" (click)="navigateLink()"
      class="navbar-brand ml-2">
      <img [src]="logo$|async" alt="SYNLAB's logo"
        class="img-fluid pl-logo-1" />
    </a>



    <div *ngIf="!(isSHFYInstance$ | async)" class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item mb-2">
          <!-- toggle close -->
          <button class="navbar-toggler collapsed d-block d-lg-none  pl-0"
            type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="material-icons">close</span>
          </button>
        </li>

        <!--check result -->
        <li class="nav-item" *ngIf="(isSuperSupportUser$ | async)">
          <a class="nav-link px-3 pl-text-color-3"
            [ngClass]="{'disabled': forceMFA$|async }"
            [href]="checkResultLink$ | async">
            <span>{{ t('checkResultLabel') }}</span>
          </a>
        </li>

        <!-- plasma link -->
        <li class="nav-item" *ngIf="!isTara">
          <a class="nav-link px-3 pl-text-color-3"
            [ngClass]="{'nav-active': (isDKInstance$ | async), 'disabled': (forceMFA$|async) || (isSHFYInstance$ | async) }"
            [href]="plasmaLink$ | async">
            <span>{{ plasmaLabel$ | async | transloco }}</span>
          </a>
        </li>

        <!-- booking link -->
        <li class="nav-item" *ngIf="(bookingLinkEnabled$ | async)">
          <a class="nav-link px-3 pl-text-color-3"
            [ngClass]="{'nav-active': (isDKInstance$ | async), 'disabled': forceMFA$|async }"
            [href]="bookingLink$ | async">
            <span>{{ t('bookingLink') }}</span>
          </a>
        </li>

        <!-- [GenePlanet] Prevention Wellness Menu -->
        <li class="nav-item" *ngIf="showPreventionWellnessLink$ | async">
          <a class="nav-link px-3 pl-text-color-3"
            [href]="preventionWellnessLink$ | async"
            target="_blank">
            <span>{{ t('preventionWellnessLabel') }}</span>
          </a>
        </li>

        <!-- FAQ -->
        <li class="nav-item" *ngIf="isDKInstance$|async">
          <a class="nav-link px-3 pl-text-color-3"
            [ngClass]="{'nav-active': (isDKInstance$|async)}"
            href="https://faq.covidresults.dk"
            target="_blank">
            <span>{{ t('faqLink') }}</span>
          </a>
        </li>

        <!-- show only in mobile view -->
        <li class="nav-item d-block d-lg-none">
          <span class="nav-link px-3 pl-text-color-3"
            (click)="startSignoutMainWindow()">
            {{ t('logoutButton') }}
          </span>
        </li>
      </ul>
    </div>
    <nav class="my-2 my-md-0 nav-bar-right" aria-label="header-nav-right">

      <!-- language dropdown -->
      <div class="dropdown language-setting">
        <button class="btn btn-default my-2 my-sm-0 d-flex align-items-center"
          type="button" id="d3" data-toggle="dropdown" aria-expanded="false"
          aria-haspopup="true">
          <i class="fa fa-globe" aria-hidden="true"></i>
        </button>
        <div class="dropdown-menu bx-shdw-1" aria-labelledby="d3">
          <div class="arrow-up"></div>
          <div class="sroll">
            <ul class="nav flex-column">
              <li *ngFor="let c of countries" class="nav-item"
                [ngClass]="{'active': (activeLang$|async) == c.countryCode}"
                (click)="changeActiveLanguage(c.countryCode)" [title]="c.name">
                <div class="d-flex flex-column align-items-center">
                  <img [src]="c.flag" alt="flag">
                  <div class="flag-name">{{c.name}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- profile's dropdown -->
      <div class="dropdown ml-1 profile-dropdown">
        <!-- small devices -->
        <span class="d-block d-lg-none dropdown-toggle"
          [routerLink]="['/account', 'profile']"
          routerLinkActive="router-link-active">
          <img src="/assets/images/new/personal-information.png" alt="">
        </span>

        <!-- desktop view -->
        <span
          class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle mr-2 d-none d-lg-block d-xl-block"
          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          {{ initial|async }}
        </span>

        <div class="dropdown-menu pl-box-shadow-1"
          aria-labelledby="dropdownMenuButton">
          <div class="arrow-up"></div>

          <!-- links -->
          <a class="dropdown-item f-2" (click)="startSignoutMainWindow()"
            href="javascript:;">
            <span class="fas fa-sign-out-alt"></span>
            {{ t('logoutButton') }}
          </a>
        </div>
      </div>
    </nav>
  </nav>
</ng-container>
