import { FormConfigInterface } from "./interfaces/form-config.interface";

export const HU_CONFIG: FormConfigInterface = {
  completeProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Hide: () => false, },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Hide: () => false, },
    { Name: "email", Required: () => true, Type: "text", Order: 3, Hide: () => false, Readonly: true, },
    { Name: "phone", Required: () => true, Type: "phone", Order: 4, Hide: () => false, },
    { Name: "locale", Required: () => true, Type: "locale-picker", Order: 5, Hide: () => false, },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 6, Hide: () => false, },
  ],
  personalInfo: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 0, Hide: () => false, },
    { Name: "family_name", Required: () => true, Type: "text", Order: 0, Hide: () => false, },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 0, Hide: () => false, },
  ],
  contact: [
    { Name: "country_address", Required: () => false, Type: "country-picker", Order: 1, Hide: () => false, },
    { Name: "phone", Required: () => true, Type: "phone", Order: 2, Hide: () => false, },
    { Name: "email", Required: () => true, Type: "text", Order: 3, Hide: () => false, },
  ],
};
