import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ShfyRoles, claimTypes, whiteLabelNames } from "src/app/appsettings";
import { ConfigService } from "../config/config.service";
import { RegisterProfileApiService } from "../register-profile-api/register-profile-api.service";
import { AuthService } from "../auth/auth.service";
import { take } from 'rxjs/operators';
import { userTypes } from "src/app/appsettings";
import { claimValues } from "src/app/appsettings";

@Injectable({
  providedIn: "root"
})
export class PreventShfyGuard  {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private registerProfileApiService: RegisterProfileApiService,
  ) {}

  private getConfig(claim: string): string {
    return String(this.config._envConfig[claim]);
  }

  private getUrlParam(param: string) {
    return new URLSearchParams(window.location.search).get(param);
  }

  private navigateToDxp(isTwoFAEnabled: boolean, isActive: boolean, userId: string, email: string) {
    this.router.navigateByUrl('/404');
    if(isTwoFAEnabled) {
      this.navigateToUrl(this.config.shfyDxpBaseURL + '/my/settings')
    } else if (!isActive) {
      this.navigateToUrl(this.config.shfyRegistrationURL + `?userId=${userId}&email=${email}`)
    } else {
      this.navigateToUrl(this.config.shfyCompleteProfileURL)
    }
  }

  private navigateToUrl(url: string) {
    window.location.href = url;
  }

  private async isLoggedInUserAllowed() {
    let isAllowed = true;
    this.auth.userInfo$.pipe(take(1)).subscribe(info => {
      const infoUserTypes = info[claimTypes.USER_TYPE];
      const isTwoFAEnabled = info[claimTypes.TWO_FACTOR_ENABLED].toLowerCase() === claimValues.TRUE;
      const isEmailVerified = info[claimTypes.EMAIL_VERIFIED].toLowerCase() === claimValues.TRUE;
      const shfyRoles: string[] = (info[claimTypes.SHFY_ROLES] || '').toLowerCase().split(',');
      const isActive = info[claimTypes.ACTIVE].toLowerCase() === claimValues.TRUE;
      const email = info[claimTypes.EMAIL];
      const userId = info[claimTypes.USERID];
      const isShfyDoctor = shfyRoles.includes(ShfyRoles.DOCTOR);

      if (!isShfyDoctor) {
        if (Array.isArray(infoUserTypes)) {
          infoUserTypes.forEach(type => {
            if (type === userTypes.SYNLAB_ACCESS_USER) {
              this.navigateToDxp(isTwoFAEnabled, isEmailVerified && isActive, userId, email);
              isAllowed = false;
            }
          });
        } else {
          if (infoUserTypes === userTypes.SYNLAB_ACCESS_USER) {
            this.navigateToDxp(isTwoFAEnabled, isEmailVerified && isActive, userId, email);
            isAllowed = false;
          }
        }
      }
    });
    return isAllowed;
  }

  private async isGuestUserAllowed(id: string, seqId: string) {
    let isAllowed = true;
    let isTwoFAEnabled = false;
    let isActive = false;
    let isEmailVerified = false;
    let userId = '';
    let email = '';

    const claims = await this.registerProfileApiService.getRegisterProfile(id, seqId).toPromise();

    claims.forEach(claim => {
      if (claim.claimType === claimTypes.USER_TYPE && claim.claimValue === userTypes.SYNLAB_ACCESS_USER)
        isAllowed = false;
      if (claim.claimType === claimTypes.TWO_FACTOR_ENABLED && claim.claimValue.toLowerCase() === claimValues.TRUE)
        isTwoFAEnabled = true;
      if (claim.claimType === claimTypes.EMAIL_VERIFIED && claim.claimValue.toLowerCase() === claimValues.TRUE)
        isEmailVerified = true;
      if (claim.claimType === claimTypes.ACTIVE && claim.claimValue.toLowerCase() === claimValues.TRUE)
        isActive = true;
      if (claim.claimType === claimTypes.EMAIL)
        email = claim.claimValue;
      if (claim.claimType === claimTypes.USERID)
        userId = claim.claimValue;
    });

    if (!isAllowed) this.navigateToDxp(isTwoFAEnabled, isEmailVerified && isActive, userId, email);

    return isAllowed;
  }

  canActivate(): any {
    const isWhiteLabeled = this.getConfig(claimTypes.WHITE_LABEL_ENABLED).toLowerCase() === 'true';
    const whiteLabelName = this.getConfig(claimTypes.WHITE_LABEL_NAME);
    if (isWhiteLabeled && whiteLabelName === whiteLabelNames.UK_SHFY) {

      var seqId = this.getUrlParam('seqId');
      var id = this.getUrlParam('id');

      if (seqId && id) {
        return this.isGuestUserAllowed(id, seqId);
      } else {
        return this.isLoggedInUserAllowed();
      }

    }
    return true;
  }
}
