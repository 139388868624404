export interface SessionTransferDto {
    value: string;
}

export interface SessionTransfer {
    idToken: string;
    accessToken: string;
}

export enum SessionTransferErrorCode {
    AccessTokenParseFailed = "A1",
    CreateDecryptorFailed = "C1",
    DecryptionFailed = "D1",
    DtoValueToBytesFailed = "D2",
    GetSessionTransferFailed = "G1",
    HashKeyToBytesFailed = "H1",
    InvalidValueBytes = "I1",
    JsonParseFailed = "J1",
    ParsedObjectInvalid = "P1",
    RemovePaddingFailed = "R1",
    TrimJsonFailed = "T1",
    UTF8DecodeFailed = "U1"
}

export class SessionTransferError {
    constructor(readonly errorCode: SessionTransferErrorCode) {}
}