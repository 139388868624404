import { Injectable } from "@angular/core";
import { FormFieldConfig } from "src/app/models/form-field-config.model";
import { ClaimService } from "src/app/services/claim/claim.service";
import { ConfigService } from "src/app/services/config/config.service";
import { I18nService } from "src/app/services/i18n/i18n.service";
import { PhoneValidationService } from "src/app/services/phone-validation/phone-validation.service";
import {of, Observable, combineLatest} from 'rxjs'
import {map} from 'rxjs/operators'
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { FormConfigInterface } from "src/app/form-configs/interfaces/form-config.interface";
export interface MyProfileSectionProps {
  visible: boolean,
  fields: FormFieldConfig[]
}

export interface MyProfileSection {
  [k: string]: MyProfileSectionProps
}


@Injectable({ providedIn: 'root'})
export class MyAccountService {
  private anon$ = this.store.select(StsSelectors.isAnonymousUser);
  private isHungaryTenant$ = this.store.select(StsSelectors.isHUTenant);
  private isCzechRepublic$ = this.store.select(StsSelectors.isCzechRepublic);
  private disabledFields$ = combineLatest([this.anon$, this.isHungaryTenant$]).pipe(map(([isAnon, isHU]) => {
    const disabledFieldsForAnonymousUser: string[] = ['given_name', 'family_name', 'email'];
    if (isHU && isAnon) return disabledFieldsForAnonymousUser;
    return ['email'];
  }))

  constructor(
    public i18nService: I18nService,
    public phoneValidator: PhoneValidationService,
    public claimService: ClaimService,
    private config: ConfigService,
    private store: Store<AppState>
  ) {}

  public get getProfileSection$(): Observable<MyProfileSection> {
    return combineLatest([
      this.isContactSectionVisible$,
      this.getFieldsForPersonalInfo$,
      this.getFieldsForContactInfo$
    ]).pipe(map(([isVisible, personalFields, contactFields]) => {
      return {
        personal: {
          visible: true,
          fields: personalFields
        },
        contact: {
          visible: isVisible,
          fields: contactFields
        }
      }
    }))
  }

  private get isContactSectionVisible$() {
    // hide contact section for HU tenant and anonymous user
    return combineLatest([this.anon$, this.isHungaryTenant$]).pipe(map(([anon, isHu]) => {
      if (isHu && anon) return false;
      return true;
    }))
  }

  private get getFieldsForPersonalInfo$() {
    const formConfig = this.config?.formConfig || ({} as FormConfigInterface);
    const fields: FormFieldConfig[] = formConfig.personalInfo;
    return this.disabledFields$.pipe(map(disabled => fields?.map((field => ({
      ...field,
        Disabled: disabled.some(f => f == field?.Name) || field.Readonly
    })))))
  }

  private get getFieldsForContactInfo$() {
    const formConfig = this.config?.formConfig || ({} as FormConfigInterface);
    const fields: FormFieldConfig[] = formConfig.contact;
    return this.disabledFields$.pipe(map(disabled => fields?.map((field => ({
      ...field,
        Disabled: disabled.some(f => f == field?.Name) || field.Readonly
    })))))
  }
}
