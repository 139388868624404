import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { AppSelector } from "src/app/@store/selectors/app.selectors";
import { I18nService } from "./i18n.service";

@Injectable({
  providedIn: "root",
})
export class CustomNgbDateParserFormatterService
  implements NgbDateParserFormatter
{
  private defLanguage: string = this.transloco.getActiveLang();
  activeLang$ = this.store.pipe(select(AppSelector.getActiveLanguage));

  constructor(
    private i18n: I18nService,
    private transloco: TranslocoService,
    private store: Store
  ) {}
  parse(value: string): NgbDateStruct {
    const format = this.i18n.getMomentDateFormatToken();
    const mom = moment(value, format, false);
    return {
      year: mom.year(),
      month: mom.month() + 1,
      day: mom.date(),
    };
  }

  /**
   * please see src\app\modules\home\pages\homepage\data.model.ts for valid formats
   */
  format(date: NgbDateStruct): string {
    const defLanguage = this.transloco.getActiveLang();
    console.log("format", { defLanguage });

    if (!date) return "";

    const mom = moment.utc([date.year, date.month - 1, date.day]);
    switch (defLanguage) {
      case "en":
        return mom.format("DD/MM/YYYY");
      case "pt":
      case "pt-PT":
        return mom.format("DD-MM-YYYY");
      default:
        return mom.format("L");
    }
  }
}
