import { Component } from '@angular/core';

@Component({
  selector: 'app-self-sampling-instruction',
  templateUrl: './self-sampling-instruction.component.html',
  styleUrls: ['./self-sampling-instruction.component.scss']
})
export class SelfSamplingInstructionComponent  {


}
