import { Action, createReducer, on } from "@ngrx/store";
import { LegalDocumentStateInterface } from "src/app/interfaces/legal-document-state.interface";
import { searchLegalDocumentsAction, searchLegalDocumentsSuccessAction, searchLegalDocumentsVersionsAction, searchLegalDocumentsVersionsSuccessAction } from "./legal-documents.actions";
import { searchLegalDocumentsReducer, searchLegalDocumentsSuccessReducer, searchLegalDocumentsVersionsReducer, searchLegalDocumentsVersionsSuccessReducer } from "./legal-documents.reducers";

export const legalDocumentFeatureKey = "legalDocument";

export const initialState: LegalDocumentStateInterface = {
    documents: {
        isLoading: false,
        list: []
    },
    versions: {
        isLoading: false,
        list: []
    }
};

const reducer = createReducer(
    initialState,
    on(searchLegalDocumentsAction, searchLegalDocumentsReducer),
    on(searchLegalDocumentsSuccessAction, searchLegalDocumentsSuccessReducer),
    on(searchLegalDocumentsVersionsAction, searchLegalDocumentsVersionsReducer),
    on(searchLegalDocumentsVersionsSuccessAction, searchLegalDocumentsVersionsSuccessReducer),
);

export function legalDocumentsReducer(state: LegalDocumentStateInterface, action: Action) {
    return reducer(state, action);
}