import { map, catchError, shareReplay, take, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Claim } from '../services/accounts/accounts.model';
import { claimValues, claimTypes } from '../appsettings';
import { ApiService } from '../services/api/api.service';
import { ConfigService } from '../services/config/config.service';
import { select, Store } from '@ngrx/store';
import { STSActions } from '../@store/actions/sts.actions';
import { StsSelectors } from '../@store/selectors/sts.selectors';

export const HAS_GENEPLANET_RESULTS_API = '/api/results/ee-has-gp-result';

export function isGeneplanetEnrolled(claims: Claim[]) {
  return claims.find(c => c?.claimType == claimTypes.GENEPLANET_USER)?.claimValue == claimValues.TRUE;
}

export function isGeneplanetAllowed(baseUrl$: Observable<string>, api: ApiService, store:Store, claims: Claim[]): Observable<boolean> {
  const synlabId = claims.find(c => c.claimType == "synlab_id")?.claimValue;
  return baseUrl$.pipe(
    take(1),
    switchMap(baseUrl => {
      return store.pipe(select(StsSelectors.hasGeneplanetOrders)).pipe(
        switchMap(hasOrders => {
          // after looking at store if hasGeneplanetOrders have been set, if null, send http request to the has-orders api
          if (hasOrders === null) return api.getHttp(`${baseUrl}${HAS_GENEPLANET_RESULTS_API}/${synlabId}`).pipe(
            map(response => `${response}`.toLowerCase() == claimValues.TRUE),
            // set hasGeneplanet to the store
            tap(hasOrders => store.dispatch(STSActions.setHasGeneplanetOrders({ hasOrders }))),
            // in any failure, set to false
            catchError(() => of(false)),
            shareReplay(1),
          )
          // return what is in the store.
          return of(hasOrders);
        })
      )
  }))
}

export function isGeneplanetEnabled(cfg: ConfigService) {
  return (cfg._envConfig.EnablePreventionWellness.toLowerCase() == claimValues.TRUE)
}

export function geneplanetClaimChange(prev:Claim[], next:Claim[]) {
  return prev.find(c => c.claimType == 'geneplanet_user')?.claimValue == next.find(c => c.claimType == 'geneplanet_user')?.claimValue
}
