import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaraMigrateRequest } from 'src/app/models/tara-migrate.model';
import { ConfigService } from 'src/app/services/config/config.service';

@Injectable({providedIn: 'root'})
export class TaraMigrateApiService {
  private readonly taraResultMigrateEndpoint = "/api/migration-requests/result";
  private readonly taraOrderMigrateEndpoint = "/api/migration-requests/order";
  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }
  migrateResults(request: TaraMigrateRequest):Observable<any>{
    return this.http.post(
      `${this.config.baseAccountUrl}${this.taraResultMigrateEndpoint}`,
      request
    )
  }
  migrateOrders(request: TaraMigrateRequest):Observable<any>{
    return this.http.post(
      `${this.config.baseAccountUrl}${this.taraOrderMigrateEndpoint}`,
      request
    )
  }
}
