import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, switchMap, share, first, map, finalize, take, tap } from "rxjs/operators";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimValues } from "src/app/appsettings";
import { ApiService } from "src/app/services/api/api.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ChangeEmailService } from "src/app/services/change-email.service";
import { ConfigService } from "src/app/services/config/config.service";

const CONFIRM_EMAIL_API = "/v2/uk/manage/accounts/confirm-email";

@Component({
  selector: "syn-activate-account",
  templateUrl: "./activate-account.component.html",
  styleUrls: ["./activate-account.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActivateAccountComponent {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  success$: Observable<boolean> = this.activeRt.queryParamMap.pipe(
    first(),
    share(),
    switchMap((params) => this.putConfirmEmail(
      params.get('seqId'),
      params.get('id'),
      params.get('change-email-verify')
    )),
    finalize(() => this.isSHFYInstance$.pipe(take(1))
      .subscribe(isShfy => isShfy ? this.shfyActivationRedirect() : this.loading$.next(false)))
  );
  private hasRedirected: boolean = false;

  error$: BehaviorSubject<string|null> = new BehaviorSubject(null);

  loginLink$: Observable<string> = this.config.envConfig$.pipe(map(c => c.BaseAuthUrl));
  isSHFYInstance$ = this.store.pipe(select(StsSelectors.isUkShfyInstance));
  isActiveUser: boolean = false;
  isLinkExpired: boolean = false;
  isDuplicateError: boolean = false;

  constructor(
    private store: Store,
    private activeRt: ActivatedRoute,
    private api: ApiService,
    private config: ConfigService,
    private changeEmail: ChangeEmailService,
    private auth: AuthService
  ) { }

  private putConfirmEmail(seqId:string, id:string, isChangeEmail?: string): Observable<boolean> {
    isChangeEmail = <any>(isChangeEmail?.toLowerCase() === 'true');
    const endpoint$ = isChangeEmail
      ? this.changeEmail.verifyEmailLink(id, seqId)
      : this.api.put(CONFIRM_EMAIL_API, { seqId, id });

    return endpoint$.pipe(
      map(res => {
        let result =  `${res}`.toLowerCase() == claimValues.TRUE;
        if (isChangeEmail) {
          document.body.classList.add('change-email-veriifed');
          if (res?.success) { result = true; }
          else {
            result = false;
            this.error$.next(res?.message);
          }
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => {
        this.isSHFYInstance$.pipe(take(1)).subscribe(isShfy => {
          document.body.classList.add('change-email-veriifed');
          this.isActiveUser = err.error.errorCode === 'SYNERR-0020';
          this.isLinkExpired = err.error.errorCode === 'SYNERR-0003';
          this.isDuplicateError = err.error.errorCode === 'SYNERR-0047';

          if (isShfy) {
            if (this.isActiveUser) {
              this.shfyActivationRedirect('is-active-user');
            } else if (this.isLinkExpired) {
              this.shfyActivationRedirect('link-expired');
            } else if (this.isDuplicateError) {
              this.shfyActivationRedirect('is-duplicate-fiscal-code');
            }
          }

          this.error$.next(err.error?.message);
        });
        return of(false);
      })
    );
  }

  private shfyActivationRedirect(status: string = 'success') {
    if (!this.hasRedirected) {
      this.hasRedirected = true;
      window.location.href = this.config.baseAuthUrl + `/Account/Login?activation-status=${status}`;
    }
  }

  redirectToLogin(): void {
    this.auth.startSignoutMainWindow();
  }
}
