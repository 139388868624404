import { RouterModule } from "@angular/router";
import { FooterComponent } from "./../../layout/footer/footer.component";
import { HeaderComponent } from "./../../layout/header/header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoWhiteSpaceValidatorDirective } from "./directives/no-white-space-validator.diretive";
import { ConfirmDiscardModalComponent } from './confirm-discard-modal/confirm-discard-modal.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from './loader/loader.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatIconModule } from "@angular/material/icon";
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DatePickerModule } from "src/app/component-modules/miscellaneous/date-picker/date-picker.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { AgreementPreviewComponent } from './agreement-preview/agreement-preview.component';
import { TrimControlDirective } from "./directives/trim-control.directive";
import { TextReplacement } from "src/app/pipes/text-replacement.pipe";
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NoWhiteSpaceValidatorDirective,
    ConfirmDiscardModalComponent,
    LoaderComponent,
    UnauthorizedComponent,
    AgreementPreviewComponent,
    TrimControlDirective,
    TextReplacement,
    WarningDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    //  ToastrModule.forRoot(),
    TranslocoModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ScrollingModule,
    MatTooltipModule,
    MatIconModule,
    FormsModule,
    NgbModule,
    DatePickerModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NoWhiteSpaceValidatorDirective,
    ConfirmDiscardModalComponent,
    TranslocoModule,
    LoaderComponent,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ScrollingModule,
    MatTooltipModule,
    MatIconModule,
    DatePickerModule,
    AgreementPreviewComponent,
    TrimControlDirective,
    TextReplacement,
    UnauthorizedComponent,
    WarningDialogComponent,
  ],
})
export class SharedModule { }
