export const LocalStorageKeys = {
  language: "App[ActiveLanguage]",
  needReloadForCookiebot: "App[needReloadForCookiebot]"
};


export enum EStorageKeys {
  REDIRECT_URL = "APP_REDIRECT_URL",
  REGISTER_USER_ID = "REG_USER_ID",
  REGISTER_REDIRECT_URL = "REG_REDIRECT_URL"
}
