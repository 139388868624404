import { Action, createReducer, on } from "@ngrx/store";
import { AppActions } from "../actions/app.actions";
import { formatLocale } from "src/app/plasma-ui-common/utils/format-locale";

export const appFeatureKey = "app";

export interface State {
  activeLanguage: string;
  loadingJSON: boolean;
}

export const initialState: State = {
  activeLanguage: "",
  loadingJSON: false,
};

const appReducer = createReducer(
  initialState,
  on(AppActions.setActiveLanguage, (state, { language }) => ({
    ...state,
    activeLanguage: formatLocale(language)
  })),
  on(AppActions.loadAppLanguage, (state) => ({
    ...state,
    loadingJSON: true
  })),
  on(
    AppActions.loadAppLanguageSuccess,
    AppActions.loadAppLanguageFailure,
    (state, { language }) => ({
      ...state,
      loadingJSON: formatLocale(language) === state.activeLanguage ? false : state.loadingJSON
    }
    )),
);

export function reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}
