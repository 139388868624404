<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet">

<div class="container">
    <div class="row navbar-brand">
        <div class="col-12">
            <img src="assets/img/synlab-logo.svg" alt="Synlab's logo" class="pl-logo-1 mt-4 mb-2"/>
        </div>
    </div>
</div>
<div class="container body text-synlab mt-5" *transloco="let t; read: 'generalTerms'; lang: (langCode$|async) ">
    <div class="row">
        <div class="col-12">
            <div>
                <h2 class="title" innerHtml="{{ t('title') }}"></h2>
                <h2 class="subtitle" innerHtml="{{ t('subtitle') }}"></h2>
            </div>
            <ol>
                <li innerHtml="{{ ('generalTerms.item1' | transloco : null : 'en') }}"></li>
                <li innerHtml="{{ t('item2') }}"></li>
                <li innerHtml="{{ t('item3') }}"></li>
                <li innerHtml="{{ t('item4') }}"></li>
                <li innerHtml="{{ t('item5') }}"></li>
                <li innerHtml="{{ t('item6') }}"></li>
                <li innerHtml="{{ t('item7') }}"></li>
                <li innerHtml="{{ t('item8') }}"></li>
                <li innerHtml="{{ t('item9') }}"></li>
                <li innerHtml="{{ t('item10') }}"></li>
                <li innerHtml="{{ t('item11') }}"></li>
                <li innerHtml="{{ t('item12') }}"></li>
                <li innerHtml="{{ t('item13') }}"></li>
            </ol>
        </div>
    </div>
</div>
