import { Injectable } from '@angular/core';
import { Claim } from 'src/app/plasma-ui-common/synlab-access-ui-components/core';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  public upsert(claims: Claim[], claim: Claim): Claim[] {
    if (!claim) return claims;
    const index = claims.findIndex(item => item.claimType === claim?.claimType);
    claims[index < 0 ? claims.length : index] = claim;
    return claims;
  }

  public find(claims: Claim[], claimType: string): Claim | undefined {
    return claims.find(claim => claim.claimType === claimType);
  }
}
