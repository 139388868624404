import { Injectable } from "@angular/core";
import { TranslocoService } from '@ngneat/transloco';
import { createEffect } from '@ngrx/effects'
import { map } from 'rxjs/operators';
import { AppActions } from 'src/app/@store/actions/app.actions';
@Injectable()
export class AppEffects {
  constructor(
    private transloco: TranslocoService
  ) {}
  setAppLanguage$ = createEffect(() => this.transloco.langChanges$.pipe(
    map(language => AppActions.setActiveLanguage({language}))
  ))
}
