import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { of } from "rxjs";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { AppState } from "src/app/@store/reducers";
import { AuthService } from "../auth/auth.service";

@Injectable({ providedIn: "root" })
export class CheckLogin  {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>
  ) { }
  canActivate() {
    const isLoggedIn = this.authService.isLoggedInObs();
    const check$ = this.authService.checkLogin();

    const sessionTransferObj = JSON.parse(localStorage.getItem("session-transfer"));

    if(sessionTransferObj != null && sessionTransferObj.accessToken) {
      this.authService.saveToState(sessionTransferObj.accessToken);
      return of(true);
    } else {
      isLoggedIn.subscribe(loggedin => {

        if (!loggedin) {
          this.store.dispatch(STSActions.checkLoginStatus({value: false}));
          return;
        }

        this.store.dispatch(STSActions.checkLoginStatus({value: true}));
      });

      return check$;
    }
  }
}
