import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Consents, ConsentsPayload } from 'src/app/pages/index/pages/notification-settings/notification.model';
import { Claim } from 'src/app/services/accounts/accounts.model';

export const reset = createAction("[RegisterProfile] Reset");
export const loadRegisterProfile = createAction(
  "[RegisterProfile] Load register profile",
  props<{ regId: string, seqId: string, bankId?: string }>()
)
export const loadRegisterProfileSuccess = createAction(
  "[RegisterProfile] Load register profile success",
  props<{ data: Claim[] }>()
)
export const loadRegisterProfileFailure = createAction(
  "[RegisterProfile Load register profile failure]",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
export const loadExistingPTProfile = createAction(
  "[RegisterProfile] Load PT user profile",
  props<{ userId: string }>()
)
export const loadExistingPTProfileSuccess = createAction(
  "[RegisterProfile] Load PT user profile success",
  props<{ data: Claim[] }>()
)
export const loadExistingPTProfileFailure = createAction(
  "[RegisterProfile Load register profile failure]",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
export const submitRegisterProfile = createAction(
  "[RegisterProfile] Submit register profile",
  props<{ regId: string, data: Claim[], consents: ConsentsPayload[], synlabId: string, seqId: string }>()
)
export const submitRegisterProfileSuccess = createAction(
  "[RegisterProfile] Submit register profile success",
)
export const submitRegisterProfileFailure = createAction(
  "[RegisterProfile Submit register profile failure]",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
export const updatePtExistingProfile = createAction(
  "[RegisterProfile] Update Existing PT profile",
  props<{ userId: string, data: Claim[], consents: ConsentsPayload[], synlabId: string, seqId: string }>()
)
export const updatePtExistingProfileSuccess = createAction(
  "[RegisterProfile] Update Existing PT profile Success",
)
export const updatePtExistingProfileFailure = createAction(
  "[RegisterProfile Update Existing PT profile failure]",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
export const signupToTaraClicked = createAction(
  "[RegisterProfile Signup button to TARA clicked]",
  props<{ userId: string }>()
)
export const signupToTaraClickedSuccess = createAction(
  "[RegisterProfile Signup button to TARA clicked success]"
)
export const signupToTaraClickedFailure = createAction(
  "[RegisterProfile Signup button to TARA clicked failure]",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
