import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ManagedPrivacyPolicyComponent } from './managed-privacy-policy/managed-privacy-policy.component';



@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    ManagedPrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    PrivacyPolicyComponent,
    ManagedPrivacyPolicyComponent
  ]
})
export class PrivacyPolicyModule { }
