import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { TaraUser, TaraUserRegisterUpdateResponse } from 'src/app/models/tara-user.model';
import { ConsentsPayload } from 'src/app/pages/index/pages/notification-settings/notification.model';
import { Claim } from 'src/app/services/accounts/accounts.model';

export const reset = createAction("[TaraRegisterProfile] Reset");
export const loadRegisterProfile = createAction(
  "[TaraRegisterProfile] Load register profile",
  props<{ regId: string }>()
)
export const loadRegisterProfileSuccess = createAction(
  "[TaraRegisterProfile] Load register profile success",
  props<{ data: TaraUser }>()
)
export const loadRegisterProfileFailure = createAction(
  "[TaraRegisterProfile] Load register profile failure",
  props<{ error: string, errorObject: HttpErrorResponse }>()
)
export const submitRegisterProfile = createAction(
  "[TaraRegisterProfile] Submit register profile",
  props<{ regId: string, data: Claim[], hasEmail?: boolean, userId?:string, consents?: ConsentsPayload[]}>()
)
export const submitRegisterProfileSuccess = createAction(
  "[TaraRegisterProfile] Submit register profile success",
  props<{ response: TaraUserRegisterUpdateResponse, claims: Claim[] }>()
)
export const submitRegisterProfileFailure = createAction(
  "[TaraRegisterProfile] Submit register profile failure",
  props<{ error: string, errorObject?: HttpErrorResponse }>()
)
