import { createReducer, on } from "@ngrx/store";
import { HeaderActions } from "../actions/header.actions";

export const headerFeatureKey = "header";

export interface State {
  visible: boolean
}

export const  initialState:State = {
  visible: true
}

export const reducer = createReducer(
  initialState,
  on(HeaderActions.setVisibility, (state, { visible}) => ({
    ...state,
    visible
  }))
)
