import { createAction, props } from "@ngrx/store";
import { EAuthenticationType } from "src/app/component-modules/registerv2/register-mfa-sms/register-mfa-sms.component";
import {
  IAuthAppData,
  IExternalAuthProvider,
  IRegisterClaims,
  ISetMFA,
  ISetMFAVerify,
  ISetPassword,
  ISetPhoneNumber,
  IVerifyPhoneNumber,
} from "../reducers/registration.reducer";

const loadRegistrationClaims = createAction(
  "[Registration] Load Registration Claims",
  props<{ id: string; seqId: string }>()
);
const loadRegistrationClaimSuccess = createAction(
  "[Registration] Load Registration Claims Success",
  props<{ claims: IRegisterClaims }>()
);
const loadRegistrationClaimFailed = createAction(
  "[Registration] Load Registration Claims Failed",
  props<{ error: any }>()
);

const loadExternalAuthProviders = createAction(
  "[Registration] Load External Auth Providers"
);
const loadExternalAuthProviderSuccess = createAction(
  "[Registration] Load External Auth Providers Success",
  props<{ items: IExternalAuthProvider[] }>()
);
const loadExternalAuthProviderFailed = createAction(
  "[Registration] Load External Auth Providers Failed",
  props<{ error: any }>()
);

const setUserPassword = createAction(
  "[Registration] Set User Password",
  props<{ regId: string; payload: ISetPassword }>()
);
const setUserPasswordSuccess = createAction(
  "[Registration] Set User Password Success"
);
const setUserPasswordFailed = createAction(
  "[Registration] Set User Password Failed",
  props<{ error: any }>()
);

const loadTaraUserInfo = createAction(
  "[Registration] Load Tara User Info",
  props<{ userId: string }>()
);
const loadTaraUserInfoSuccess = createAction(
  "[Registration] Load Tara User Info Success",
  props<{ email: string }>()
);
const loadTaraUserInfoFailed = createAction(
  "[Registration] Load Tara User Info Failed",
  props<{ error: any }>()
);

const taraResendEmailActivation = createAction(
  "[Registration] Tara Resend Email Activation",
  props<{ userId: string }>()
);
const taraResendEmailActivationSuccess = createAction(
  "[Registration] Tara Resend Email Activation Success"
);
const taraResendEmailActivationFailed = createAction(
  "[Registration] Tara Resend Email Activation Failed",
  props<{ error: any }>()
);

const activateUserAccount = createAction(
  "[Registration] Activate Tara User",
  props<{ userId: string, accountType?: string }>()
);
const activateTaraUserSuccess = createAction(
  "[Registration] Activate Tara User Success",
  props<{ activated: boolean }>()
);
const activateTaraUserFailed = createAction(
  "[Registration] Activate Tara User Failed",
  props<{ error: any }>()
);

const updatePhoneNumber = createAction(
  "[Registration] Update Phone Number",
  props<{ payload: ISetPhoneNumber }>()
);
const updatePhoneNumberSuccess = createAction(
  "[Registration] Update Phone Number Success",
  props<{ phone: string }>()
);
const updatePhoneNumberFailed = createAction(
  "[Registration] Update Phone Number Failed",
  props<{ error: any }>()
);

const verifyPhoneNumber = createAction(
  "[Registration] Verify Phone Number",
  props<{ payload: IVerifyPhoneNumber }>()
);
const verifyPhoneNumberSuccess = createAction(
  "[Registration] Verify Phone Number Success",
  props<{ success: boolean }>()
);
const verifyPhoneNumberFailed = createAction(
  "[Registration] Verify Phone Number Failed",
  props<{ error: any }>()
);

const enableMFA = createAction(
  "[Registration] Enable MFA",
  props<{ payload: ISetMFA }>()
);
const enableMFASuccess = createAction(
  "[Registration] Enable MFA Success",
  props<{ data: any; authType: EAuthenticationType }>()
);
const enableMFAFailed = createAction(
  "[Registration] Enable MFA Failed",
  props<{ error: any }>()
);

const enableMFAVerify = createAction(
  "[Registration] Enable MFA Verify",
  props<{ payload: ISetMFAVerify }>()
);
const enableMFAVerifySuccess = createAction(
  "[Registration] Enable MFA Verify Success",
  props<{
    data: { result: ConstrainBooleanParameters; type: EAuthenticationType };
  }>()
);
const enableMFAVerifyFailed = createAction(
  "[Registration] Enable MFA Verify Failed",
  props<{ error: any }>()
);

const resetMFAInProgress = createAction(
  "[Registration] Reset MFA In Progress",
  props<{ authType: EAuthenticationType }>()
);

const setAuthenticatorAppData = createAction(
  "[Registration] Set Authenticator App Data",
  props<{ data: IAuthAppData }>()
);

const loadExistingUserClaims = createAction(
  "[Registration Load Existing User Claims",
  props<{ userid: string }>()
);

const acceptPrivacyPolicy = createAction(
  "[Registration] Accept Privacy Policy"
);

export const RegisterActions = {
  acceptPrivacyPolicy,
  setAuthenticatorAppData,
  enableMFAVerify,
  enableMFAVerifySuccess,
  enableMFAVerifyFailed,
  verifyPhoneNumber,
  verifyPhoneNumberSuccess,
  verifyPhoneNumberFailed,
  enableMFA,
  enableMFASuccess,
  enableMFAFailed,
  updatePhoneNumber,
  updatePhoneNumberSuccess,
  updatePhoneNumberFailed,
  loadRegistrationClaims,
  loadExistingUserClaims,
  loadRegistrationClaimSuccess,
  loadRegistrationClaimFailed,
  loadExternalAuthProviders,
  loadExternalAuthProviderSuccess,
  loadExternalAuthProviderFailed,
  setUserPassword,
  setUserPasswordSuccess,
  setUserPasswordFailed,
  loadTaraUserInfo,
  loadTaraUserInfoSuccess,
  loadTaraUserInfoFailed,
  taraResendEmailActivation,
  taraResendEmailActivationSuccess,
  taraResendEmailActivationFailed,
  activateUserAccount,
  activateTaraUserSuccess,
  activateTaraUserFailed,
  resetMFAInProgress,
};
